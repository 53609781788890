<template>
    <div class="row vh-100 d-flex justify-content-center">
        <div class="col-12 align-self-center">
            <div class="row">
                <div class="col-lg-5 mx-auto">
                    <div class="card">
                        <div class="card-body p-3">

                            <form @submit.prevent="submit" class="form-horizontal auth-form">

                                <div class="form-group mb-2">
                                    <label for="email">Email / Mobile No.</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="email" required v-model="uid" placeholder="Enter Email / Mobile No.">
                                    </div>
                                </div>


                                <div class="form-group mb-0 row">
                                    <div class="col-12" v-if="!processing">
                                        <button class="btn btn-primary btn-block waves-effect waves-light" type="submit">Get Password <i class="fas fa-sign-in-alt ml-1"></i></button>
                                    </div>
                                    <div class="col-12" v-else>
                                        <button class="btn btn-primary btn-block waves-effect waves-light" type="button">Processing...</button>
                                    </div>
                                </div>
                            </form>
                            <div class="m-3 text-center text-muted">
                                <p class="mb-0">Don't have an account ?  <router-link :to="{name:'register'}" href="auth-register.html" class="text-primary ml-2">Free Resister</router-link></p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    data(){
        return{
            uid:"", processing:false,
        }
    },
    methods:{
        submit(){
            this.processing = true
            this.$user.forgetPassword(this.uid).then((res)=>{
                this.processing = false
                if(res.data.success === true){
                    this.$swal("Your password is sent to your email address.")
                    this.$router.push({name:"login"})
                }else{
                    this.$swal("Invalid credentials")
                }
            })
        }
    }

}
</script>

<style scoped>

</style>